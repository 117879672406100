import React from 'react'
import './styles.scss'

const SectionTitle = ({ title }) => (
  <div className="SectionTitle-wrapper">
    <h2 className="SectionTitle">
      {title}
    </h2>
  </div>
)

export default SectionTitle

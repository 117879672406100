import React from 'react';
import { Link } from 'gatsby';
import Logo from '@images/pirsos-horizontal-logo.png';
import './styles.scss';

const Footer = () => (
  <footer className="Footer">
    <div className="Footer-main">
      <img src={Logo} alt="Five grid logo" />
    </div>
    <div className="Footer-nav">
      <Link to="/#about_pirsos" data-scroll>about</Link>
      <Link to="/#benefits" data-scroll>benefits</Link>
      <Link to="/#features" data-scroll>features</Link>
      <Link to="/#contact" data-scroll>contact</Link>
    </div>
    <div className="Footer-meta">
      <div className="meta-line">Pirsos is a service developed and provided by Cense Data Inc.</div>
      <div className="meta-line">Office: 1618 Quebec Street Suite 1910</div>
      <div className="meta-line">V6A 0T5 Vancouver, British Columbia, Canada</div>
    </div>
  </footer>
);

export default Footer;
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import Hamburger from './components/Hamburger';
import Logo from '@images/pirsos-horizontal-logo.png';
import './styles.scss';

const Header = () => {
  const [isOpen, toggleOpen] = useState(false);
  return (
    <Container>
      <header className="Header">
        <div>
          <Link to="/">
            <img
              alt="Five Grid logo"
              height={50}
              src={Logo}
            />
          </Link>
        </div>
        <nav className={isOpen ? 'menu-open' : ''}>
          <Link to="/#about_pirsos" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>about</Link>
          <Link to="/#benefits" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>benefits</Link>
          <Link to="/#features" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>features</Link>
          <Link to="#contact" className="hero-btn" data-scroll>Request trial</Link>
        </nav>
        <Hamburger
          isOpen={isOpen}
          toggleOpen={() => toggleOpen(!isOpen)}
        />
      </header>
    </Container>
  );
};

export default Header;